<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card tile height="100%">
          <v-card-title class="text-h5"
            ><v-icon class="mr-2">{{ mainCard.icon }}</v-icon
            >{{ mainCard.title }}</v-card-title
          >
          <v-card-subtitle>{{ mainCard.text }}</v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card tile height="100%">
          <v-card-title><v-icon class="mr-2">mdi-clock-fast</v-icon> Acesso rápido ao cliente:</v-card-title>
          <quick-account-access />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="item in cards" :key="item.index" :cols="item.cols">
        <v-card tile height="100%" class="card-outter">
          <v-card-title class="text-h5"><v-icon class="mr-2">mdi-label-outline</v-icon>{{ item.title }} </v-card-title>
          <v-card-subtitle>{{ item.text }}</v-card-subtitle>
          <v-card-actions class="card-actions">
            <v-btn class="mb-1" outlined text @click="redirect(item.href)"> {{ item.textButton }} </v-btn>
            <v-btn v-if="item.extraLinks.length" class="mb-1" outlined text @click="redirect(item.extraLinks[0])">{{ item.extraLinks[1] || '' }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {
    QuickAccountAccess: () => import('@/modules/home/components/QuickAccountAccess')
  },
  data: () => ({
    mainCard: {
      title: 'Starlord',
      icon: 'mdi-map-marker-outline',
      // eslint-disable-next-line prettier/prettier
      text:
        'Configuração e implantação de clientes e ERPs da Mercafacil. Assim como, gestão de usuários internos e externos, visualização de incidentes que ocorreram no processamento de dados dos clientes e interação com diversas ferramentas internas que auxiliam o fluxo de trabalho do suporte técnico e demais áreas.'
    },
    cards: [
      {
        title: 'Plataforma Mercafacil',
        text: '',
        cols: 4,
        href: 'https://web.mercafacil.com/#/home',
        textButton: 'Ir para plataforma',
        extraLinks: []
      },
      {
        title: 'Documentação Starlord',
        text: 'Documentação e tutoriais de utilização do Starlord.',
        cols: 4,
        href: 'https://mercafacil.getoutline.com/doc/painel-adminstarlord-Nhkeyks9IQ',
        textButton: 'Ir para documentações',
        extraLinks: []
      },
      {
        title: 'Airflow',
        text: 'Processamento de dados automatizado da Mercafacil. Verificar documentação.',
        cols: 4,
        href: 'https://airflow-cluster.mercafacil.com/home',
        textButton: 'Ir para o Airflow',
        extraLinks: ['https://mercafacil.getoutline.com/doc/dags-e-repositorios-nrlXsXjjL7', 'DAGs e Repositórios']
      }
    ]
  }),
  methods: {
    redirect(link) {
      return window.open(link, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.card-outter {
  position: relative;
  padding-bottom: 48px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
